import "./App.css";
import GlitchButton from "./components/GlitchTest/GlitchButton";

function App() {
    return (
        <div className="parent">
            <div className="navbar">
                <GlitchButton>me_</GlitchButton>
                <GlitchButton>resume_</GlitchButton>
                <GlitchButton>experience_</GlitchButton>
                <GlitchButton>projects_</GlitchButton>
                <GlitchButton>contact_</GlitchButton>
            </div>
            <div className="body-container">
                <div id="sectionName" className="container">
                    Backend
                    <div className="stack" style={{ "--stacks": "3" }}>
                        <span style={{ "--index": "0", "--time": "340ms" }}>
                            Arnald Morato Catafal
                        </span>
                        <span style={{ "--index": "1", "--time": "340ms" }}>
                            Arnald Morato Catafal
                        </span>
                        <span style={{ "--index": "2", "--time": "340ms" }}>
                            Arnald Morato Catafal
                        </span>
                    </div>
                    <span className="right">Developer</span>
                </div>
            </div>
            <div id="sectionResume"></div>
            <div id="sectionExperience"></div>
        </div>
    );
}

export default App;
