import "./GlitchButton.css";

function GlitchButton(props) {
    const { onClick } = props;
    return (
        <button className="glitch-button" onClick={onClick}>
            <div className="glitch-text" style={{ "--stacks": "3" }}>
                <span
                    style={{
                        "--index": "0",
                    }}
                >
                    {props.children}
                </span>
                <span
                    style={{
                        "--index": "1",
                    }}
                >
                    {props.children}
                </span>
                <span
                    style={{
                        "--index": "2",
                    }}
                >
                    {props.children}
                </span>
            </div>
        </button>
    );
}

export default GlitchButton;
